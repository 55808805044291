import React from 'react';
import Livematch from './Livematch'; 
import Navbar from './Navbar';

  function Live() {
  return (
    <>
    <Navbar />
    <div className='div'> 
      <div className='div-sports1'> </div>
       <Livematch />
       <div className='div-sports2'> </div>
        
    </div>
    </> 
  )
}



export default Live;
